import "./EtihadLeader.scss";
import { images } from "../../../constants";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const EtihadLeader = () => {
  const { t } = useTranslation();

  return (
    <div className="leader__container">
      <div className="top-card">
        <div className="bgLines">
          <div className="line greenLine">
            <div className="rightSide"></div>
            <div className="leftSide">
              <p>{t("leader.l1")}</p>
            </div>
          </div>
          <div className="line centerLine">
            <div className="rightSide"></div>
            <div className="leftSide">
              <p>{t("leader.l2")}</p>
            </div>
          </div>
          <div className="line greenLine">
            <div className="rightSide"></div>
            <div className="leftSide">
              <p>{t("leader.l3")}</p>
            </div>
          </div>
        </div>
        <div className="leaderImg-container">
          <div className="yellowBox"></div>
          <div className="leaderImg"></div>
          <div className="iconCircle">
            <img src={images.leaderIcon} alt="" />
          </div>
        </div>
        <div className="splitLine ">
          <div class="line-with-circles"></div>
        </div>
      </div>

      <div className="mainPoints">
        <div className="side rightSide-Points ">
          <img src={images.certificates} alt="" />
          <p className="side-title">{t("leader.l4")}</p>

          <div className="certCard">
            <div className="rightIcon">
              <div className="iconHolder">
                <img src={images.certificates} alt="" />
              </div>
            </div>
            <div className="content">
              <p className="datePill">31-12-1996</p>
              <p className="certTitle">
                {t("leader.l6")}
              </p>
              <p className="certProvider">
                {t("leader.l7")}
              </p>
              <p className="certMessage">
                "Effects of Split Exercise Sessions on Excess Post-Exercise
                Oxygen Consumption and Resting Metabolic Rate"
              </p>
            </div>
          </div>
          <div className="certCard">
            <div className="rightIcon">
              <div className="iconHolder">
                <img src={images.certificates} alt="" />
              </div>
            </div>
            <div className="content">
              <p className="datePill">13-12-1993</p>
              <p className="certTitle">{t("leader.l8")}</p>
              <p className="certProvider">
                {t("leader.l7")}
              </p>
              <p className="certMessage">
                "Validity of Bioelectrical Impedance Analysis in The Prediction
                of Percent Body Fat in Men"
              </p>
            </div>
          </div>
          <div className="certCard">
            <div className="rightIcon">
              <div className="iconHolder">
                <img src={images.certificates} alt="" />
              </div>
            </div>
            <div className="content">
              <p className="datePill">8-11-1989</p>
              <p className="certTitle">
                {t("leader.l9")}
              </p>
              <p className="certProvider">
                {t("leader.l10")}
              </p>
            </div>
          </div>
        </div>
        <div className="splitLine ">
          <div class="line-with-circles"></div>
        </div>
        <div className="side leftSide-Points">
          <img src={images.currentRole} alt="" />
          <p className="side-title">{t("leader.l5")}</p>
          <ul className="role-list">
            <li className="role">
              <span>
                {t("leader.l11")}
              </span>
            </li>
            <li className="role">
              <span>
                {t("leader.l12")}
              </span>
            </li>
            <li className="role">
              <span>
                {t("leader.l13")}
              </span>
            </li>
            <li className="role">
              <span>
                {t("leader.l14")}
              </span>
            </li>
            <li className="role">
              <span>
                {t("leader.l15")}
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div className="about">
        <p className="aboutHeading">{t("leader.l16")}</p>
        <p className="aboutPoint">
          {t("leader.l17")}
        </p>
        <p className="aboutPoint">
          {t("leader.l18")}
        </p>
        <p className="aboutPoint">
          {t("leader.l19")}
        </p>
        <p className="aboutPoint">
          {t("leader.l20")}
        </p>
        <p className="aboutPoint">
          {t("leader.l21")}
        </p>

        {i18n.language != "ar" ?
          (<>
            <p className="aboutPoint center">President of the Saudi Universities Sports Federation</p>
            <p className="aboutPoint center">Prof. Khalid bin Saleh Almuzaini</p>
          </>) : (<>
            <p className="aboutPoint">وأخيراً فلا تخفى أهمية الرياضة الجامعية حيث يمكن أن تساهم في دعم رياضة الوطن من خلال التالي:</p>


            <ul>
              <li>
                زيادة عدد الرياضيين والفرق المشاركة في المسابقات الدولية، حيث يوجد
                اتحاد دولي للرياضة الجامعية في عضويته حوالي 170 دولة ينظم مسابقات
                دولية يتنافس فيها أفضل الرياضيين في العالم.
              </li>
              <li>
                تطوير وتشجيع رياضات جديدة ذات شعبية وأهمية عالمية لكنها ليست شعبية
                على المستوى المحلي.
              </li>
              <li>فتح المجال لممارسة التحكيم في المنافسات الجامعية.</li>
              <li>
                فتح المجال لكافة المستويات الفنية للشباب لممارسة الرياضة التنافسية
                عند مستويات قد تمكنهم للانتقال لرياضة النخبة.
              </li>
              <li>
                يمكن أن تحتضن مراكز أولمبية مصغرة داخل الجامعات لتدريب رياضيي النخبة
                طوال العام وتقديم الخدمات اللازمة مثل الاختبارات البدنية
                والفسيولوجية والبحث العلمي.
              </li>
              <li>
                تتوزع الجامعات في كل أرجاء المملكة وتوجد في مناطق الأطراف والأرياف
                وهذا سيساهم في توسيع استقطاب الرياضيين وفتح الفرصة للجميع للمشاركة
                في الرياضة.
              </li>
              <li>
                رياضة الجامعات هي فرصة للتركيز على فئة عمرية مهمة جداً للألعاب
                الأولمبية من 17-25 سنة.
              </li>
              <li>
                يمكن أن يصل المستهدف من المجتمع الجامعي إلى أكثر من 7 مليون نسمة
                وهذا يشمل الطلاب والطالبات وذويهم والكادر الأكاديمي والإداري، لذلك
                يمكن تحقيق أهداف وطنية متمثلة في زيادة عدد الممارسين للنشاط البدني
                المرتبط بالصحة.
              </li>
              <li>
                تعد بيئة ملائمة لتطوير رياضة ذوي الإعاقة والفئات الخاصة وإقامة
                البطولات لهم.
              </li>
              <li>
                توليد وظائف وإتاحة الفرصة لقيادات شابة من الجنسين لممارسة مهن قيادية
                في التدريب والتحكيم والإدارة الرياضية.
              </li>
            </ul>

            <p className="aboutPoint ">وفقنا الله جميعاً لخدمة وطننا الغالي وتحقيق أهدافه الاستراتيجية.</p>

            <p className="aboutPoint center">رئيس الاتحاد السعودي للرياضة الجامعية</p>
            <p className="aboutPoint center">أ.د. خالد بن صالح المزيني</p>
          </>)}
      </div>
    </div>
  );
};

export default EtihadLeader;
