import React, { useEffect } from "react";
import "./ManagerialStructure.scss";
import { images } from "../../../constants";
import { useTranslation } from "react-i18next";

const ManagerialStructure = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="admin-structure">
      <div className="headingImg">
        <img className="headingIcon" src={images.headingIcon} alt="" />
        <div className="overlay"></div>
      </div>

      <div className="data">
        <h3> {t("struct1")}</h3>
        <ul className="list-unstyled">
          <li>{t("struct2")}</li>
          <li>{t("struct3")}</li>
          <li>{t("struct4")}</li>
          <li>{t("struct5")}</li>
          <li>{t("struct6")}</li>
          <li>{t("struct7")}</li>
        </ul>

        <h5>
          {t("struct8")}
        </h5>
        <h5>{t("struct9")}</h5>
      </div>

      <div className="org-chart">
        <div className="top-part">
          <div className="levelup">
            <div className="box">
              {t("struct3")}
              <span className="dashes"></span>
              <span className="separator"></span>
            </div>
          </div>
          <div className="levelmiddle">
            <div className="box">
              {t("struct6")}
              <span className="dashes"></span>
              <span className="separator"></span>
            </div>
            <div className="box">
              {t("struct5")}
              <span className="dashes"></span>
              <span className="separator"></span>
            </div>
          </div>
          <div className="leveldown">
            <div className="box">
              {t("struct4")}
              <span className="dashes"></span>
              <span className="separator"></span>
            </div>
            {/* <div className="box">
              الأمين العام
                <span className="dashes"></span>
                <span className="separator"></span>
              </div> */}
          </div>
          <div className="leveldown2">
            <div className="box">
              {t("struct7")}
              <span className="dashes"></span>
              <span className="separator"></span>
            </div>
          </div>
        </div>

        {/* <div className="leveldown">
              <div className="box">
              الأمين العام
                <span className="dashes"></span>
                <span className="separator"></span>
              </div>
            </div> */}

        <div className="low-part">
          <div className="levelup">
            <div className="box">
              {t("struct10")}
              <span className="dashes"></span>
              <span className="separator"></span>
            </div>
            <div className="box">
              {t("struct11")}
              <span className="dashes"></span>
              <span className="separator"></span>
            </div>
            <div className="box">
              {t("struct12")}
              <span className="dashes"></span>
              <span className="separator"></span>
            </div>
            <div className="box">
              {t("struct13")}
              <span className="dashes"></span>
              <span className="separator"></span>
            </div>
          </div>
          <div className="leveldown">
            <div className="box">
              {t("struct14")}
              <span className="dashes"></span>
              <span className="separator"></span>
            </div>
            <div className="box">
              {t("struct15")}
              <span className="dashes"></span>
              <span className="separator"></span>
            </div>
            <div className="box">
              {t("struct16")}
              <span className="dashes"></span>
              <span className="separator"></span>
            </div>
            <div className="box">
              {t("struct17")}
              <span className="dashes"></span>
              <span className="separator"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagerialStructure;
