import React, { useEffect, useState } from "react";
import { images } from "../../../constants";
import "./Main.scss";
import { getLatestMatchesResult } from "../../../API/susfApi";
import { format } from "date-fns";
import { arSA } from "date-fns/locale";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

export default function Main() {
  const [latestMatches, setMatchesData] = useState([]);
  const [latestMatchesMain, setMatchesDataMain] = useState([]);
  const [dispalayMen, setDispalayMen] = useState("displayb");
  const [dispalayWomen, setDispalayWomen] = useState("displayn");

  const [dispalayMenCss, setdispalayMenCss] = useState("button boys activeBtnLatest");
  const [dispalayWomenCss, setDispalayWomenCss] = useState("button girls  deActiveBtnLatest");
  const [dispalayMenImage, setdispalayMenImage] = useState(images.boyWhite);
  const [dispalayWomenImage, setDispalayWomenImage] = useState(images.girl);

  const [dispalayMenCss2, setdispalayMenCss2] = useState("button boys activeBtnLatest");
  const [dispalayWomenCss2, setDispalayWomenCss2] = useState("button girls  deActiveBtnLatest");
  const [dispalayMenImage2, setdispalayMenImage2] = useState(images.boyWhite);
  const [dispalayWomenImage2, setDispalayWomenImage2] = useState(images.girl);


  const { t } = useTranslation();

  useEffect(() => {
    getLatestMatchesResult()
      //.then((response) => response.json())
      .then((data) => {
        if (data && data.data && data.data.resultData) {
          let latestResult = data.data.resultData
            .slice(
              0,
              data.data.resultData.length > 200 ? 200 : data.data.resultData.length
            )
            .map((x) => {
              return {
                id: x.matchTeam.id,
                team1Name: x.matchTeam.team1Name,
                team1NameEn: x.matchTeam.team1NameEn,
                team1Logo: x.matchTeam.team1Logo,
                team2Name: x.matchTeam.team2Name,
                team2NameEn: x.matchTeam.team2NameEn,
                team2Logo: x.matchTeam.team2Logo,
                matchDate: x.matchTeam.dateTime,
                sportName: x.matchTeam.sportName,
                sportNameEn: x.matchTeam.sportNameEn,
                sportLogo: x.matchTeam.sportLogo,
                team1Score: x.matchTeam.scoreVisible
                  ? x.matchTeam.team1Score
                  : "-",
                team2Score: x.matchTeam.scoreVisible
                  ? x.matchTeam.team2Score
                  : "-",
                compType: x.matchTeam.compType,
              };
            });

          if (latestResult == null || latestResult == undefined || latestResult.length <= 0) {
            latestResult = data.data.matches
              .slice(
                0,
                data.data.matches.length > 200 ? 200 : data.data.matches.length
              )
              .map((x) => {
                return {
                  id: x.matchTeam._id,
                  team1Name: x.matchTeam.team1Name,
                  team1NameEn: x.matchTeam.team1NameEn,
                  team1Logo: x.matchTeam.team1Logo,
                  team2Name: x.matchTeam.team2Name,
                  team2NameEn: x.matchTeam.team2NameEn,
                  team2Logo: x.matchTeam.team2Logo,
                  matchDate: x.matchTeam.dateTime,
                  sportName: x.matchTeam.sportName,
                  sportNameEn: x.matchTeam.sportNameEn,
                  sportLogo: x.matchTeam.sportLogo,
                  team1Score: x.matchTeam.scoreVisible
                    ? x.matchTeam.team1Score
                    : "-",
                  team2Score: x.matchTeam.scoreVisible
                    ? x.matchTeam.team2Score
                    : "-",
                  compType: x.matchTeam.compType,
                };
              });
          }

          setMatchesData(
            latestResult.filter(function (el) {
              return el.compType === 1;
            }).sort((a, b) => {
              return new Date(a.matchDate).getTime() -
                new Date(b.matchDate).getTime()
            }).reverse().slice(0, 10)
          );
          setMatchesDataMain(latestResult);
        }
      });
  }, []);

  const filterResult = (dataType) => {
    if (dataType == 1) {
      setdispalayMenImage(images.boyWhite);
      setDispalayWomenImage(images.girl);
      setdispalayMenCss("button boys activeBtnLatest");
      setDispalayWomenCss("button girls deActiveBtnLatest");
    } else {
      setdispalayMenImage(images.boy);
      setDispalayWomenImage(images.girls);
      setdispalayMenCss("button boys deActiveBtnLatest");
      setDispalayWomenCss("button girls activeBtnLatest");
    }

    setMatchesData(
      latestMatchesMain.filter(function (el) {
        return el.compType === dataType;
      }).sort((a, b) => {
        return new Date(a.matchDate).getTime() -
          new Date(b.matchDate).getTime()
      }).reverse().slice(0, 10)
    );
  };

  const displayMenMeter = () => {
    setdispalayMenImage2(images.boyWhite);
    setDispalayWomenImage2(images.girl);
    setdispalayMenCss2("button boys activeBtnLatest");
    setDispalayWomenCss2("button girls deActiveBtnLatest");

    setDispalayMen("displayb");
    setDispalayWomen("displayn");
  }

  const displayWomenMeter = () => {
    setdispalayMenImage2(images.boy);
    setDispalayWomenImage2(images.girls);
    setdispalayMenCss2("button boys deActiveBtnLatest");
    setDispalayWomenCss2("button girls activeBtnLatest");

    setDispalayWomen("displayb");
    setDispalayMen("displayn");
  }

  return (
    <>
      <section className="main-container">
        <div className="inner-container">
          <div className="right-side ">
            <div className="row w-100  m-0">
              {/* h-100 */}
              <div className="activities col-md-10 bg-white h-100 triangle2 ">
                <div className="mobile-heading">
                  <img
                    className="mobile-only"
                    src={images.activitiesGreen}
                    alt=""
                  />
                  <h1>{t("latestMatches")}</h1>
                </div>
                <div className="scores-container bg-white">
                  {latestMatches.length > 0 ? (
                    <>
                      {latestMatches.map((item, index) => (
                        <>
                          <div className="match" key={index}>
                            <div className="sides">
                              <div className="side green-bg">
                                <div className="side-img">
                                  <img src={item.team1Logo} alt="" />
                                </div>
                                <span>{i18n.language == "ar" ? item.team1Name : item.team1NameEn}</span>
                              </div>
                              <p>{t("VS")}</p>
                              <div className="side yellow-bg">
                                <div className="side-img">
                                  <img src={item.team2Logo} alt="" />
                                </div>
                                <span>{i18n.language == "ar" ? item.team2Name :  item.team2NameEn}</span>{" "}
                              </div>
                            </div>
                            <div className="info-section">
                              <p className="sport-type">
                                <img src={item.sportLogo} alt="" />
                                {i18n.language == "ar" ? item.sportName : item.sportNameEn}
                              </p>
                              <p className="score">
                                <span>{item.team1Score}</span>
                                <span>:</span>
                                <span>{item.team2Score}</span>
                              </p>
                              <div className="date">
                                {item.compType === 1 ? (
                                  <>
                                    <img src={images.boy} alt="" />
                                  </>
                                ) : (
                                  <>
                                    <img src={images.girl} alt="" />
                                  </>
                                )}

                                <div>
                                  <p>
                                    {format(new Date(item?.matchDate), "dd", {
                                      locale: arSA,
                                    })}
                                  </p>
                                  <p>
                                    {i18n.language == "ar" ?
                                      (<>
                                        {format(new Date(item?.matchDate), "MMMM", { locale: arSA, })}
                                      </>) : (<>
                                        {format(new Date(item?.matchDate), "MMMM", { })}
                                      </>)
                                    }
                                    
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </>
                  ) : (
                    <>
                      <div className="emptyData">
                        <img src="/content/images/nodata.png" alt="no data" />
                        <br />
                        <span>{t("noDataFound")}</span>
                      </div>
                    </>
                  )}
                </div>
                <div className="footer">
                  <NavLink to={'/competitions'} >
                    <p className="label">
                      {/* جميع المسابقات والنتائج */}
                      {t("viewMore")}
                      {/* <i className="fa-solid fa-arrow-down"></i> */}
                    </p>
                  </NavLink>
                  <div className="buttons">
                    <div className={dispalayMenCss} onClick={() => filterResult(1)} >
                      <img src={dispalayMenImage} alt="" />
                      <p>{t("male")}</p>
                    </div>
                    <div className={dispalayWomenCss} onClick={() => filterResult(2)} >
                      <img src={dispalayWomenImage} alt="" />
                      <p>{t("female")}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="logo col-md-2">
                <img src={images.activities} alt="" />
              </div>
            </div>
          </div>

          <div className="left-side">
            <div className="row w-100 h-100 m-0">
              <div className="logo col-md-2">
                <img src={images.indicator} alt="" />
                {/* <h2>الترتيب</h2> */}
              </div>
              <div className="indicators col-md-10 bg-white h-100 triangle">
                <div className="mobile-heading">
                  <div className="mobile-only">
                    <img src={images.indicatorGreen} alt="" />
                    {/* <h2>الترتيب</h2> */}
                  </div>
                  <h1>{t("moasher")}</h1>
                </div>
                <div className="meters-container">
                  <div className={dispalayMen}>
                    <div className="meter-data">
                      <div className="count">1</div>
                      <div className="meter">
                        <p>{i18n.language == "ar" ? "جامعة الإمام عبد الرحمن بن فيصل": "Imam Abdul Rahman bin Faisal University"}</p>
                        <div className="body">
                          <div
                            className="fill greenBG"
                            style={{ width: "100%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="meter-data">
                      <div className="count">2</div>
                      <div className="meter">
                        <p>{i18n.language == "ar" ? "جامعة الملك سعود":"King Saud University"}</p>
                        <div className="body">
                          <div
                            className="fill redBG"
                            style={{ width: "90%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="meter-data">
                      <div className="count">3</div>
                      <div className="meter">
                        <p>{i18n.language == "ar" ? "جامعة الملك عبد العزيز":"King Abdulaziz University"}</p>
                        <div className="body">
                          <div
                            className="fill purpleBG"
                            style={{ width: "80%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="meter-data">
                      <div className="count"><label className="redColor">*</label> 3</div>
                      <div className="meter">
                        <p>{i18n.language == "ar" ? "جامعة القصيم":"Al Qussaim university"}</p>
                        <div className="body">
                          <div
                            className="fill yellowBG"
                            style={{ width: "80%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="meter-data">
                      <div className="count">5</div>
                      <div className="meter">
                        <p>{i18n.language == "ar" ? "جامعة الملك فهد للبترول والمعادن":"King Fahd University of Petroleum and Minerals"}</p>
                        <div className="body">
                          <div
                            className="fill blueBG"
                            style={{ width: "60%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="meter-data">
                      <div className="count">6</div>
                      <div className="meter">
                        <p>{i18n.language == "ar" ? "جامعة الباحة":"Al Baha university"}</p>
                        <div className="body">
                          <div
                            className="fill purpleBG"
                            style={{ width: "50%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="meter-data">
                      <div className="count">7</div>
                      <div className="meter">
                        <p>{i18n.language == "ar" ? "جامعة جازان":"Gazan University"}</p>
                        <div className="body">
                          <div
                            className="fill brownBG"
                            style={{ width: "40%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="meter-data">
                      <div className="count">8</div>
                      <div className="meter">
                        <p>{i18n.language == "ar" ? "جامعة طيبة":"Taibah University"}</p>
                        <div className="body">
                          <div
                            className="fill orangeBG"
                            style={{ width: "30%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="meter-data">
                      <div className="count">9</div>
                      <div className="meter">
                        <p>{i18n.language == "ar" ? "جامعة حائل":"Hael University"}</p>
                        <div className="body">
                          <div
                            className="fill yellowBG"
                            style={{ width: "20%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="meter-data">
                      <div className="count">10</div>
                      <div className="meter">
                        <p>{i18n.language == "ar" ? "الجامعة الإسلامية":"Islamic University"}</p>
                        <div className="body">
                          <div
                            className="fill redBG"
                            style={{ width: "10%" }}
                          ></div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="meter-data">                      
                      <div className="meter"> */}
                    <p className="redp"><label className="redColor">*</label> 
                    {i18n.language == "ar" ? "مكرر ":"Redundant"}
                    </p>
                    {/* </div>
                    </div> */}

                  </div>

                  <div className={dispalayWomen}>
                    <div className="meter-data">
                      <div className="count">1</div>
                      <div className="meter">
                        <p>{i18n.language == "ar" ? "جامعة الأميرة نورة بنت عبد الرحمن":"Princess Nora bint AbdulRahman University"}</p>
                        <div className="body">
                          <div
                            className="fill greenBG"
                            style={{ width: "100%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="meter-data">
                      <div className="count">2</div>
                      <div className="meter">
                        <p>{i18n.language == "ar" ? "جامعة الإمام عبد الرحمن بن فيصل":"Imam Abdul Rahman bin Faisal University"}</p>
                        <div className="body">
                          <div
                            className="fill redBG"
                            style={{ width: "90%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="meter-data">
                      <div className="count">3</div>
                      <div className="meter">
                        <p>{i18n.language == "ar" ? "جامعة الملك سعود":"King Saud University"}</p>
                        <div className="body">
                          <div
                            className="fill purpleBG"
                            style={{ width: "80%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="meter-data">
                      <div className="count">4</div>
                      <div className="meter">
                        <p>{i18n.language == "ar" ? "جامعة الملك عبد العزيز":"King Abdulaziz University"}</p>
                        <div className="body">
                          <div
                            className="fill yellowBG"
                            style={{ width: "70%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="meter-data">
                      <div className="count">5</div>
                      <div className="meter">
                        <p>{i18n.language == "ar" ? "جامعة طيبة":"Taibah University"}</p>
                        <div className="body">
                          <div
                            className="fill blueBG"
                            style={{ width: "60%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="meter-data">
                      <div className="count">6</div>
                      <div className="meter">
                        <p>{i18n.language == "ar" ? "جامعة عفت":"Effat University"}</p>
                        <div className="body">
                          <div
                            className="fill purpleBG"
                            style={{ width: "50%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="meter-data">
                      <div className="count">7</div>
                      <div className="meter">
                        <p>{i18n.language == "ar" ? "جامعة الباحة":"Al Baha university"}</p>
                        <div className="body">
                          <div
                            className="fill brownBG"
                            style={{ width: "40%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="meter-data">
                      <div className="count">8</div>
                      <div className="meter">
                        <p>{i18n.language == "ar" ? "كليات ومعاهد الهيئة الملكية ينبع":"Colleges and institutes of the Royal Commission in Yanbu"}</p>
                        <div className="body">
                          <div
                            className="fill orangeBG"
                            style={{ width: "30%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="meter-data">
                      <div className="count">9</div>
                      <div className="meter">
                        <p>{i18n.language == "ar" ? "جامعة دار الحكمة":"Dar Al-Hekma University"}</p>
                        <div className="body">
                          <div
                            className="fill yellowBG"
                            style={{ width: "20%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="meter-data">
                      <div className="count">10</div>
                      <div className="meter">
                        <p>{i18n.language == "ar" ? "جامعة حائل":"Hael University"}</p>
                        <div className="body">
                          <div
                            className="fill redBG"
                            style={{ width: "10%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className={dispalayWomen}>
                    <div className="meter-data">
                      <div className="count">11,515</div>
                      <div className="meter">
                        <p>جامعة الأميرة نورة بنت عبد الرحمن</p>
                        <div className="body">
                          <div
                            className="fill greenBG"
                            style={{ width: "90%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="meter-data">
                      <div className="count">9,825</div>
                      <div className="meter">
                        <p>جامعة الإمام عبد الرحمن بن فيصل</p>
                        <div className="body">
                          <div
                            className="fill purpleBG"
                            style={{ width: "80%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="meter-data">
                      <div className="count">9,100</div>
                      <div className="meter">
                        <p>جامعة الملك سعود</p>
                        <div className="body">
                          <div
                            className="fill blueBG"
                            style={{ width: "75%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="meter-data">
                      <div className="count">8,110</div>
                      <div className="meter">
                        <p>جامعة الملك عبد العزيز</p>
                        <div className="body">
                          <div
                            className="fill redBG"
                            style={{ width: "70%" }}
                          ></div>
                        </div>
                      </div>
                    </div>

                    <div className="meter-data">
                      <div className="count">7,265</div>
                      <div className="meter">
                        <p>جامعة طيبة</p>
                        <div className="body">
                          <div
                            className="fill yellowBG"
                            style={{ width: "68%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="meter-data">
                      <div className="count">7,140</div>
                      <div className="meter">
                        <p>جامعة عفت</p>
                        <div className="body">
                          <div
                            className="fill orangeBG"
                            style={{ width: "40%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="meter-data">
                      <div className="count">7,000</div>
                      <div className="meter">
                        <p>جامعة الباحة</p>
                        <div className="body">
                          <div
                            className="fill brownBG"
                            style={{ width: "35%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="meter-data">
                      <div className="count">6,815</div>
                      <div className="meter">
                        <p>كليات ومعاهد الهيئة الملكية ينبع</p>
                        <div className="body">
                          <div
                            className="fill greenBG"
                            style={{ width: "33%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="meter-data">
                      <div className="count">6,625</div>
                      <div className="meter">
                        <p>جامعة دار الحكمة</p>
                        <div className="body">
                          <div
                            className="fill redBG"
                            style={{ width: "30%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="meter-data">
                      <div className="count">6,435</div>
                      <div className="meter">
                        <p>جامعة حائل</p>
                        <div className="body">
                          <div
                            className="fill yellowBG"
                            style={{ width: "20%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={dispalayMen}>
                    <div className="meter-data">
                      <div className="count">12,045</div>
                      <div className="meter">
                        <p>جامعة الإمام عبدالرحمن بن فيصل</p>
                        <div className="body">
                          <div
                            className="fill greenBG"
                            style={{ width: "90%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="meter-data">
                      <div className="count">11,040</div>
                      <div className="meter">
                        <p>جامعة الملك سعود</p>
                        <div className="body">
                          <div
                            className="fill purpleBG"
                            style={{ width: "80%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="meter-data">
                      <div className="count">10,850</div>
                      <div className="meter">
                        <p>جامعة الملك عبدالعزيز</p>
                        <div className="body">
                          <div
                            className="fill blueBG"
                            style={{ width: "75%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="meter-data">
                      <div className="count">10,400</div>
                      <div className="meter">
                        <p>جامعة القصيم</p>
                        <div className="body">
                          <div
                            className="fill redBG"
                            style={{ width: "70%" }}
                          ></div>
                        </div>
                      </div>
                    </div>

                    <div className="meter-data">
                      <div className="count">10,380</div>
                      <div className="meter">
                        <p>جامعة الملك فهد للبترول والمعادن</p>
                        <div className="body">
                          <div
                            className="fill yellowBG"
                            style={{ width: "68%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="meter-data">
                      <div className="count">8,735</div>
                      <div className="meter">
                        <p>جامعة الباحة</p>
                        <div className="body">
                          <div
                            className="fill orangeBG"
                            style={{ width: "40%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="meter-data">
                      <div className="count">8,315</div>
                      <div className="meter">
                        <p>جامعة جازان</p>
                        <div className="body">
                          <div
                            className="fill brownBG"
                            style={{ width: "35%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="meter-data">
                      <div className="count">8,120</div>
                      <div className="meter">
                        <p>جامعة طيبة</p>
                        <div className="body">
                          <div
                            className="fill greenBG"
                            style={{ width: "33%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="meter-data">
                      <div className="count">7,880</div>
                      <div className="meter">
                        <p>جامعة حائل</p>
                        <div className="body">
                          <div
                            className="fill redBG"
                            style={{ width: "30%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="meter-data">
                      <div className="count">7,788</div>
                      <div className="meter">
                        <p>الجامعة الإسلامية</p>
                        <div className="body">
                          <div
                            className="fill yellowBG"
                            style={{ width: "20%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="emptyData">
                    <img src="/content/images/nodata2.png" alt="no data" />
                  </div> */}
                </div>
                <div className="footer">
                  <div className="buttons">
                    <div className={dispalayMenCss2} onClick={() => displayMenMeter()}>
                      <img src={dispalayMenImage2} alt="" />
                      <p>{t("male")}</p>
                    </div>
                    <div className={dispalayWomenCss2} onClick={() => displayWomenMeter()}>
                      <img src={dispalayWomenImage2} alt="" />
                      <p>{t("female")}</p>
                    </div>
                  </div>
                  {/* <p className="label">
                    تفاصيل المؤشر
                    <i className="fa-solid fa-arrow-down"></i>
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
