export const en = {
  webTitle:"The Saudi Universities Sports Federation",
  nav: {
    main: "Home",
    ethad: {
      main: "Sports Federation",
      about: "About the Federation",
      chef: "President of the Federation",
      structure: "Administrative structure",
      law: "Regulations and Laws"
    },
    competion: "Competitions",
    otherServices: {
      main: "E-services",
      nashet: "Nashet portal",
      ahlia: "Eligibility portal",
      result: "National Teams and Results Portal",
      competition: "Competitions portal",
      tasks: "Task management portal",
      email: "Email"
    },
    news: {
      main: "Media Center",
      news: "News",
      events: "Events",
      vidios: "Media",
      images: "Photos",
      ads: "Advertisements",
      media: "Media Kit",
      prints: "Publications"
    }
  },
  latestMatches: "Latest Matches",
  moasher: "Universities Sports Index",
  viewMore: "Show More",
  male: "Male",
  female: "Female",
  VS: "VS",

  season: "Season",
  university: "Universities",
  competitions: "Competitions",
  internationalComp: "International competitions",
  theMale: "Males",
  theFemale: "Females",

  latestComp: "Latest competitions",
  eventsCenter: "Media Center",

  forms: "Questionnaires & forms",
  all: "All",
  prints: "Publications",
  ads: "Advertisements",
  events: "Events",

  latestNews: "Latest News",

  date: "Date",
  dateFilter: "Sort by date",
  orderTable: "table of contect",
  timeLine: "Timeline",
  latestResult: "Latest results",
  nextMatch: "Upcoming Match",
  matches: "Matches",
  team: "Team",
  points: "Points",
  raw: "Draw",
  lose: "Loss",
  win: "win",
  play: "Play",

  nextLbl: "Upcoming Match",
  finishedLbl: "Game Over",
  cancelLbl: "Canceled",
  postponedLbl: "Postponed",
  clearSearch: "Clear Search",
  gatherings: "Gatherings",
  from: "From",
  to: "To",
  particiUniversity: "Participating universities",
  riad: "Riyadh",
  playername: "Player name",
  playernumber: "Player number",
  matchevents: "Match events",
  minute: "In the minute",
  goal: "Goal",
  scoredpenality: "Penalty kick scored",
  penalty: "Penalty",
  noDataFound: "No results",
  moshahada: "View",
  newsSource: "Source",
  footer: {
    contactwithus: "Follow Us on Social Media",
    contactinfo: "Contact Information",
    address: "Al-Takhasosi Street - Al-Ma'athar Al-Shamaliya - Riyadh 12712",
    usfulLinks: "Useful Links",
    eduministry: "Ministry of education",
    eduShooan: "University Affairs Council",
    arabicnatian: "Arab University Sports Federation",
    internationalnatian: "International University Sports Federation (FISU)",
    termscondtions: "Terms and Conditions",
    support: "Technical Support",
    copyrights: "All rights reserved to the Saudi Federation for University Sports"
  },
  "vision": "Vision",
  "visionT": "To achieve excellence and leadership in sports at Saudi universities, reaching advanced global levels that align with the values, principles, and objectives of the universities.",
  "Message": "Message",
  "MessageT": "To support and develop university sports by creating a sports community that encourages the application of sportsmanship and emphasizes the promotion of physical activity among university students. We aim to establish organized rules and policies for activities, represent and coordinate the positions and opinions of Saudi universities in local, regional, and international sports events.",
  "Objectives": "Objectives",
  "ObjectivesT1": "Organize high-quality sports activities and events for university students through the adoption of a distinctive organizational framework and collaborative efforts with national sports federations.",
  "ObjectivesT2": "Supervise sports activities among institutions under the supervision of the Ministry of Education.",
  "ObjectivesT3": "Support and develop Saudi university sports.",
  "ObjectivesT4": "Represent Saudi universities in local, regional, and international sports events.",
  "ObjectivesT5": "Coordinate positions and opinions in local, regional, and international university sports events.",
  "ObjectivesT6": "Elevate university sports from being merely extracurricular activities to systematic programs that emphasize the importance of participating in sports as an integral part of students' holistic development for their professional lives.",
  "ObjectivesT7": "Develop educational sports programs by adopting a set of ethical standards that align with contemporary educational and sports issues.",
  "ObjectivesT8": "Support scientific studies and research that promote academic and athletic excellence and enhance communication among universities.",
  "struct1": "The administrative structure of the Saudi Universities Sports Union consists of:",
  "struct2": "President of the Fedration (Minister of Education)",
  "struct3": "Board of Directors",
  "struct4": "SUSF  President",
  "struct5": "General Assembly",
  "struct6": "Advisory Body",
  "struct7": "Secretary-General of the SUSF",
  "struct8": "To manage the affairs of the fedration, permanent specialized committees are formed to facilitate the Union's tasks and assist in decision-making.",
  "struct9": "These committees include",
  "struct10": "Administrative and Financial Committee",
  "struct11": "Technical Committee",
  "struct12": "Competitions Committee",
  "struct13": "Media Committee",
  "struct14": "Legal Committee",
  "struct15": "Organizing Committee",
  "struct16": "Medical Committee",
  "struct17": "Studies and Development Committee",
  leader:{
    l1:"Biography of",
    l2:"The President of the Saudi Universities Sports Union",
    l3:"Prof.Dr. Khalid Almuzaini",
    l4:"Academic Qualifications",
    l5:"Current Positions",
    l6:"Biography of the President of the Saudi Universities Sports Union",
    l7:"University of Kansas, United States - Dissertation title",
    l8:"Master's degree in Exercise Physiology",
    l9:"Bachelor of Education - Physical Education (with Second Class Honors)",
    l10:"King Saud University - Riyadh",
    l11:"President of the Saudi Universities Sports Union - Ministry of Education",
    l12:"Deputy Chief of the Asian University Sports Federation",
    l13:"Member of the Board of Directors of the Saudi Olympic Academy - Saudi Olympic and Paralympic Committee - Ministry of Sports",
    l14:"Editor-in-Chief of the Journal of Sports Science and Physical Education and Professor of Exercise Physiology - College of Sports Science and Physical Activity - King Saud ",
    l15:"Chairman of the Saudi Physical Fitness Standards Committee - Ministry of Sports",
    l16:"Speech of the Union President",
    l17:"The Saudi Universities Sports Federation (SUSF) is on a mission to redefine university sports, creating a national model that embodies the highest international standards. Our goal is to support the Kingdom's vision by enhancing participation in sports across all levels, abilities, and genders, ensuring equal opportunities for everyone. We aim for our athletes to represent the Kingdom on international stages, reflecting our nation's status and potential.",
    l18:"To achieve these ambitions, SUSF is rolling out development programs and projects designed to uplift the university sports environment. Our initiatives cater to the diverse needs of the university community, from casual participants to elite athletes and national teams. We're setting up elite sports centers to provide year-round support, including comprehensive training and wellness resources, to help our athletes shine globally.",
    l19:"Gender equality is at the heart of our efforts. SUSF is committed to fostering a supportive environment for women's sports, offering programs that cater to various abilities, including those of individuals with disabilities. Education and awareness are key; we're launching training for sports leaders and students, alongside diverse educational events, to instill values and principles that align with our strategic objectives.",
    l20:"Our digital transformation consolidates SUSF's activities into an online portal, featuring platforms like the Universities' Sports Activity Performance Indicator (USAPI), which motivates universities to excel in sports and physical activities. This digital approach not only streamlines our operations but also builds a comprehensive database to support future development in university sports.",
    l21:"University sports play a crucial role in nurturing national sports talent, offering pathways from grassroots to elite levels, and promoting inclusivity and diversity. Our initiatives aim to increase international participation, develop new sports, and provide opportunities across the Kingdom, including in rural areas. Targeting the vibrant 17 to 25 age group, university sports stand as a dynamic force for advancing national health and activity objectives. As we embark on this journey, may we be guided in our endeavors to uplift our cherished nation and fulfill its strategic aspirations.",
  }
};

