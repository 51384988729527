import React, { useEffect, useState } from "react";
import { format, differenceInMinutes } from "date-fns";
import { arSA } from "date-fns/locale";
import { getLatestNews } from "../../../API/susfApi";
import "./Activities.scss";
import { images } from "../../../constants";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const Activities = () => {
  const { t } = useTranslation();
  const [newsAct, setNewsAct] = useState([]);
  useEffect(() => {
    getLatestNews("limit=12&moduleNumber=2").then((result) => {

      var latesNews = result.data.latestNews.map((x) => {
        return {
          id: x.id,
          title: x.title,
          titleEn: x.titleEn,
          publishDate: x.publishDate,
          image: x.image,
          isEnglishApproved: x.isEnglishApproved ? x.isEnglishApproved : false
        };
      });
      setNewsAct(latesNews);
    });
  }, []);

  return (
    <div className="activities__container">
      <div className="headingImg">
        <img className="headingIcon" src={images.headingIcon} alt="" />
        <div className="overlay"></div>
      </div>

      <div className="activities-list container">
        <div className="row activities-row">

          {newsAct.map((newsItem, index) => (
            <>

            {i18n.language == "ar" || (i18n.language == "en" && newsItem.isEnglishApproved) ?
                (<>

              <div className="col-lg-4 col-md-6 col-sm-12 activity-card" index={index}>
                <div className="card-img">
                  <img src={newsItem.image} alt="" />
                </div>
                <div className="card-info">
                  <div className="card-date">
                    <div className="dateBox">
                      <p className="day">{format(new Date(newsItem.publishDate), "dd", { locale: arSA, })}</p>
                      <p className="month">{i18n.language == "ar" ? (
                        <>
                          {format(new Date(newsItem.publishDate), "MMMM", { locale: arSA, })}
                        </>) : (<>
                          {format(new Date(newsItem.publishDate), "MMMM", {})}
                        </>)
                      }</p>
                      <hr />
                      <p className="year">{format(new Date(newsItem.publishDate), "yyyy", { locale: arSA, })}</p>
                    </div>
                  </div>
                  <NavLink className="card-label" to={`/details/${newsItem.id}`} >
                    <p>{i18n.language == "ar" ? newsItem.title : newsItem.titleEn}</p>
                  </NavLink>
                </div>
              </div>

            </>):(<></>)}
            
            </>
          ))}


          {/* <div className="col-lg-4 col-md-6 col-sm-12 activity-card">
            <div className="card-img">
              <img src={images.cardImg} alt="" />
            </div>
            <div className="card-info">
              <div className="card-date">
                <div className="dateBox">
                  <p className="day">06</p>
                  <p className="month">سبتمبر</p>
                  <hr />
                  <p className="year">2023</p>
                </div>
              </div>
              <p className="card-label">تدشين مسابقات الموسم الثاني عشر</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 activity-card">
            <div className="card-img">
              <img src={images.cardImg} alt="" />
            </div>
            <div className="card-info">
              <div className="card-date">
                <div className="dateBox">
                  <p className="day">06</p>
                  <p className="month">سبتمبر</p>
                  <hr />
                  <p className="year">2023</p>
                </div>
              </div>
              <p className="card-label">تدشين مسابقات الموسم الثاني عشر</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 activity-card">
            <div className="card-img">
              <img src={images.cardImg} alt="" />
            </div>
            <div className="card-info">
              <div className="card-date">
                <div className="dateBox">
                  <p className="day">06</p>
                  <p className="month">سبتمبر</p>
                  <hr />
                  <p className="year">2023</p>
                </div>
              </div>
              <p className="card-label">تدشين مسابقات الموسم الثاني عشر</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 activity-card">
            <div className="card-img">
              <img src={images.cardImg} alt="" />
            </div>
            <div className="card-info">
              <div className="card-date">
                <div className="dateBox">
                  <p className="day">06</p>
                  <p className="month">سبتمبر</p>
                  <hr />
                  <p className="year">2023</p>
                </div>
              </div>
              <p className="card-label">تدشين مسابقات الموسم الثاني عشر</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 activity-card">
            <div className="card-img">
              <img src={images.cardImg} alt="" />
            </div>
            <div className="card-info">
              <div className="card-date">
                <div className="dateBox">
                  <p className="day">06</p>
                  <p className="month">سبتمبر</p>
                  <hr />
                  <p className="year">2023</p>
                </div>
              </div>
              <p className="card-label">تدشين مسابقات الموسم الثاني عشر</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 activity-card">
            <div className="card-img">
              <img src={images.cardImg} alt="" />
            </div>
            <div className="card-info">
              <div className="card-date">
                <div className="dateBox">
                  <p className="day">06</p>
                  <p className="month">سبتمبر</p>
                  <hr />
                  <p className="year">2023</p>
                </div>
              </div>
              <p className="card-label">تدشين مسابقات الموسم الثاني عشر</p>
            </div>
          </div> */}




        </div>
      </div>
    </div>
  );
};

export default Activities;
