export const ar = {
  webTitle:"الاتحاد السعودي للرياضة الجامعية",
  nav: {
    main: "الرئيسية",
    ethad: {
      main: "الاتحاد الرياضي",
      about: "عن الاتحاد",
      chef: "رئيس الاتحاد",
      structure: "الهيكل الإداري",
      law: "اللوائح والقوانين"
    },
    competion: "المسابقات",
    otherServices: {
      main: "الخدمات الإلكترونية",
      nashet: "بوابة نشط",
      ahlia: "بوابة الأهلية",
      result: "بوابة المنتخبات والنتائج",
      competition: "بوابة المسابقات",
      tasks: "نظام إدارة المهام",
      email: "البريد الإلكتروني"
    },
    news: {
      main: "المركز الإعلامي",
      news: "الأخبار",
      events: "الفعاليات",
      vidios: "الوسائط",
      images: "الصور",
      ads: "الإعلانات",
      media: "الملف الإعلامي",
      prints: "المطبوعات"
    }
  },
  latestMatches: "أحدث المباريات",
  moasher: "مؤشر الرياضة الجامعية",
  viewMore: "عرض المزيد",
  male: "طلاب",
  female: "طالبات",
  VS: "VS",

  season: "الموسم",
  university: "الجامعات",
  competitions: "المسابقات",
  internationalComp: "المسابقات الدولية",
  theMale: "الطلاب",
  theFemale: "الطالبات",

  latestComp: "أحدث المسابقات",
  eventsCenter: "المركز الإعلامي",

  forms: "استبيانات ونماذج",
  all: "جميع ",
  prints: "المطبوعات",
  ads: "الإعلانات",
  events: "الفعاليات",

  latestNews: "آخر الأخبار",

  date: "التاريخ",
  dateFilter: "تصفية بالتاريخ",
  orderTable: "جدول الترتيب",
  timeLine: "الخط الزمني",
  latestResult: "أحدث النتائج",
  nextMatch: "المباراة القادمة",
  matches: "المباريات",
  team: "الفريق",
  points: "النقاط",
  raw: "تعادل",
  lose: "خسارة",
  win: "فوز",
  play: "لعب",

  nextLbl: "القادمة",
  finishedLbl: "تم الانتهاء",
  cancelLbl: "ألغيت",
  postponedLbl: "مؤجلة",
  clearSearch: "مسح الفرز",
  gatherings: "التجمعات",
  from: "من",
  to: "الي",
  particiUniversity: "الجامعات المشاركة",
  riad: "الرياض",
  playername: "اسم لاعب",
  playernumber: "رقم لاعب",
  matchevents: "أحداث المباراة",
  minute: "فى الدقيقه",
  goal: "هدف",
  scoredpenality: "ضربة جزاء مسجلة",
  penalty: "ضربة جزاء",
  noDataFound: "لا يوجد",
  moshahada: "مشاهدة",
  newsSource: "مصدر الخبر",
  footer: {
    contactwithus: "تابعنا على حسابات التواصل الإجتماعي",
    contactinfo: "معلومات التواصل",
    address: "شارع التخصصي - المعذر الشمالي - الرياض 12712",
    usfulLinks: "روابط مفيدة",
    eduministry: "وزارة التعليم",
    eduShooan: "مجلس شؤون الجامعات",
    arabicnatian: "الاتحاد العربي للرياضة الجامعية",
    internationalnatian: "الاتحاد الدولي للرياضة الجامعية",
    termscondtions: "الشروط والأحكام",
    support: "الدعم الفني",
    copyrights: "جميع الحقوق محفوظة للاتحاد السعودي للرياضة الجامعية"
  },
  "vision": "الرؤية",
  "visionT": "تحقيق التميز والريادة للرياضة في الجامعات السعودية للوصول إلى مستويات عالمية متقدمة بما يتناسب مع قيم الجامعات ومبادئها وأهدافها .",
  "Message": "الرسالة",
  "MessageT": "رعاية الحركة الرياضية الجامعية ودعمها وتطويرها ورفع مستواها من خلال مجتمع رياضــــــــــــي يشجع على تطبيق الروح الرياضية ويؤكـــــــــــــــد علــى تعزيز ممارســة النشاط الرياضي ونشــــــــــره بين طلاب الجامعات وتحديد القواعد ورسم السياسات المنظمــــــــة للأنشطة وتمثيل وتنسيق المواقف والآراء للجامعات السعـــــــــودية في المشاركات الرياضية المحلية والإقليمية والدولية.",
  "Objectives": "الأهداف",
  "ObjectivesT1": "تنظيم أنشطة ولقاءات رياضية عالية الجودة لطلاب الجامعات من خلال تبني إطار تنظيمي مميز والتعاون المشترك مع الاتحادات الرياضية الوطنية.",
  "ObjectivesT2": "الإشراف على الأنشطة الرياضية بين المؤسسات الواقعة تحت إشراف وزارة التعليم.",
  "ObjectivesT3": "رعاية الحركة الرياضية الجامعية السعودية و دعمها و تطويرها.",
  "ObjectivesT4": "تمثيل الجامعات السعودية فى المشاركات الرياضية المحلية و الإقليمية و الدولية.",
  "ObjectivesT5": "تنسيق المواقف و الآراء في المشاركات الرياضية الجامعية المحلية و الإقليمية و الدولية.",
  "ObjectivesT6": "الارتقاء بالرياضة الجامعية من مجرد نشاط طلابي غير صفي إلى برامج منهجيه تؤكد على أهمية المشاركة في الأنشطة الرياضية كأحد عناصر النمو المتكامل لإعداد طلاب الجامعة للحياة المهنية.",
  "ObjectivesT7": "تطوير البرامج الرياضية التربوية من خلال تبني مجموعة من المعايير الأخلاقية بما يتناسب مع القضايا التربوية الرياضية المعاصرة.",
  "ObjectivesT8": "دعم الدراسات والبحوث العلمية التي تؤدي إلى التوافق والانسجام لتحقيق التميز الأكاديمي والرياضي وبما يعزز التواصل بين الجامعات.",
  "struct1": "يتكون الهيكل الإداري للاتحاد الرياضي للجامعات السعودية من",
  "struct2": "رئيس مجلس إدارة الاتحاد ( وزير التعليم ).",
  "struct3": "مجلس إدارة الاتحاد.",
  "struct4": "رئيس الاتحاد.",
  "struct5": "الجمعية العمومية.",
  "struct6": "الهيئة الاستشارية.",
  "struct7": "أمين عام الاتحاد.",
  "struct8": "ولتسيير أمور الاتحاد يتم تكوين لجان متخصصة دائمة تسهل مهام الاتحاد وتساعد على اتخاذ القرارات,",
  "struct9": "ويوضح ذلك الشكل التالي :",
  "struct10": "اللجنة الادارية والمالية",
  "struct11": "اللجنة الفنية",
  "struct12": "لجنة المسابقات",
  "struct13": "لجنة الاعلام",
  "struct14": "اللجنة القانونية",
  "struct15": "اللجنة التنظيمية",
  "struct16": "اللجنة الطبية",
  "struct17": "لجنة الدراسات والتطوير",
  leader:{
    l1:"السيرة الذاتية",
    l2:"لرئيس الاتحاد الرياضي للجامعات السعودية",
    l3:"أ.د. خالد بن صالح المزيني",
    l4:"المؤهلات العلمية",
    l5:"العمل الحالي",
    l6:"دكتوراه الفلسفة في فسيولوجيا الجهد البدني (مع تخصص فرعي في الاحصاء)",
    l7:"جامعة كنساس - الولايات المتحدة الامريكية - عنوان الرسالة",
    l8:"ماجستير في فسيولوجيا الجهد البدني",
    l9:"بكالوريوس تربية - تخصص تربية بدنية (مع مرتبة الشرف الثانية)",
    l10:"جامعة الملك سعود - الرياض",
    l11:"رئيس الاتحاد الرياضي للجامعات السعودية - وزارة التعليم",
    l12:"نائب رشيس الاتحاد الاسيوي للرياضة الجامعية",
    l13:"عضو مجلس ادارة الاكاديمية الاولمبية السعودية - اللجنة الاولمبية والبارالمبية السعودية - وزارة الرياضة",
    l14:"رئيس تحرير مجلة علوم الرياضة والتربية البدنية واستاذ فسيولوجيا الجهد البدني - كلية علوم الرياضة والنشاط البدني - جامعة الملك سعود - الرياض",
    l15:"رئيس اللجنة السعودية لمعايير اللياقة البدنية - وزارة الرياضة",
    l16:"كلمة رئيس الاتحاد",
    l17:"يسعى الاتحاد السعودي للرياضة الجامعية لبناء نموذج وطني للرياضة الجامعية متوائم مع أفضل الممارسات الدولية، وذلك لتحقيق أهداف رؤية المملكة المرتبطة بالنشاط البدني والرياضي، من خلال رياضة جامعية يرتفع فيها نسبة المشاركين بمختلف قدراتهم ومستوياتهم وأهدافهم، وتتحقق فيها عدالة الفرص بين الجنسين وبين الممارسين، ويتحقق من خلالها تمثيل دولي يلائم مكانة وإمكانات المملكة، لتكون الرياضة الجامعية بيئة داعمة لرياضة الوطن. ومن هذا المنطلق يقوم الاتحاد حالياً بتنفيذ حزمة من المشاريع والبرامج التطويرية لتمكين بيئة الرياضة الجامعية من تحقيق هذه الأهداف الاستراتيجية.",
    l18:"وتوسع الاتحاد الرياضي في أنشطته وبرامجه لتشمل عدة شرائح من المجتمع الجامعي وذلك لتلبية احتياجات وأهداف الممارسين المختلفة، مثل الممارسة من أجل الصحة أو الترويح أو التنافس وصولاً إلى رياضيي النخبة والمنتخبات. ويتم حاليا تجهيز مراكز رياضيي النخبة وهي عبارة عن حاضنات تهتم بهؤلاء الرياضيين المتميزين طوال العام وتقدم لهم الاختبارات البدنية والفسيولوجية والنفسية وكل ما يعينهم على تحقيق المنجزات في المنافسات الدولية. ويدعم الاتحاد في جميع أنشطته تكافؤ الفرص بين الجنسين (الطلاب والطالبات)، كما أنه الاتحاد مستمر في تمكين رياضة المرأة وجعل الرياضة الجامعية بيئة حاضنة ومطورة للرياضة النسائية في المملكة. وتهتم برامج الاتحاد في مختلف القدرات ومستويات الأداء، كما يقدم الاتحاد برامج متنوعة وأنشطة ومنافسات لذوي الاعاقة.",
    l19:"ولأهمية التوعية والتثقيف، وخصوصاً لكون البيئة الجامعية خصبة للتعلم ولترسيخ مجموعة من القيم والمبادئ السامية، يطلق الاتحاد برامج تدريب للقيادات الرياضية الجامعية وللطلبة ومسابقات توعوية متنوعة والكثير من الفعاليات والفيديوهات التوعوية للمجتمع الجامعي، والتي صممت لتتوائم مع الأهداف الاستراتيجية للاتحاد وتحقق المستوى الثقافي المأمول من الناحية الصحية والتنافسية. ",
    l20:"ولذلك يجمع الاتحاد جميع أنشطته في بوابة الكترونية تشمل عدة منصات، منها منصة مؤشر أداء الجامعات في النشاط الرياضي ومنصة الأهلية ومنصة المنافسات في الألعاب المختلفة ومنصة المسابقات التوعوية، كل ذلك رغبة في أتمتة كامل أعمال وأنشطة وإجراءات الاتحاد ليسهل التعامل معها ولتصبح قاعدة معلومات يستفاد منها في المشاريع القادمة لمزيد من التطوير في الرياضة الجامعية. ",
    l21:"كما طور الاتحاد أدوات تحفيز للجامعات منها مؤشر أداء الجامعات في النشاط البدني والرياضي وذلك رغبة في تحفيز الجامعات على المشاركة في أنشطة الاتحاد وزيادة مستوى التنافسية في الأنشطة الرياضية الجامعية، وهذا المؤشر يصنف الجامعات بناء على أدائها وجهودها المبذولة في تفعيل الأنشطة البدنية والرياضية داخل الجامعات وفي المنافسات بين الجامعات. ",
    
  }
};  
