import React, { useEffect, useState } from "react";
import { format, differenceInMinutes } from "date-fns";
import { arSA } from "date-fns/locale";
import { getLatestNews } from "../../../API/susfApi";
import "./News.scss";
import { images } from "../../../constants";
import { NavLink } from "react-router-dom";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

const News = () => {
  const { t } = useTranslation();
  const [news, setNews] = useState([]);
  useEffect(() => {
    getLatestNews("limit=12&moduleNumber=1").then((result) => {
      var latesNews = result.data.latestNews.map((x) => {
        return {
          id: x.id,
          title: x.title,
          titleEn: x.titleEn,
          publishDate: x.publishDate,
          image: x.image,
          isEnglishApproved: x.isEnglishApproved ? x.isEnglishApproved : false
        };
      });
      setNews(latesNews);
    });
  }, []);

  return (
    <div className="mediaNews-container">
      <div className="headingImg">
        <img className="headingIcon" src={images.headingIcon} alt="" />
        <div className="overlay"></div>
      </div>

      <div className="news-list container">
        <div className="row cards-row">
          {news.map((newsItem, index) => (
            <>

              {i18n.language == "ar" || (i18n.language == "en" && newsItem.isEnglishApproved) ?
                (<>

                  <div
                    className="col-lg-4 col-md-6 col-sm-12 news-card"
                    index={index}
                  >
                    <div className="card-img">
                      <img src={newsItem.image} alt="" />
                    </div>
                    <div className="card-body">
                      <div className="card-body__top">
                        <p className="news-date">
                          <span>
                            {i18n.language == "ar" ?
                              (<>
                                {format(new Date(newsItem.publishDate), "dd MMMM , yyyy", { locale: arSA })}
                              </>)
                              : (<>
                                {format(new Date(newsItem.publishDate), "dd MMMM , yyyy", {})}
                              </>)}
                          </span>
                          <img src={images.calendar} alt="" />
                        </p>
                        <NavLink to={`/details/${newsItem.id}`}>
                          <p className="news-text">{i18n.language == "ar" ? newsItem.title : newsItem.titleEn}</p>
                        </NavLink>
                      </div>
                      <div className="card-body__bottom">
                        <hr />
                        <p className="news-location">{t("riad")}</p>
                      </div>
                    </div>
                  </div>

                </>) : (<></>)}

            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default News;
